<template>
  <div class="club">
    <top-head></top-head>
    <div class="menu-wrapper">
      <div class="active-menu frcb" @click="showMenu">
        <div>{{ active.title }}</div>
        <div>
          <img class="arrow-icon" :class="show ? 'active' : ''" src="../../assets/images/down_arrow.png" alt="" />
        </div>
      </div>
      <div style="height: calc(100vh - 28vw);overflow:auto">
        <div class="menu-list" :style="{ maxHeight: show ? `${18 * clubList.length}vw` : '0' }">
          <div class="menu-item" :class="active.title == item.title ? 'active-color' : ''" @click="chooseMenu(item)"
            v-for="(item, index) in clubList" :key="index">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <template>
      <div class="club-box" v-if="id !== -1">
        <swiper-box :height="'56vw'" :list="swipers"></swiper-box>
        <div class="club-title-bar">
          <div class="club-title">
            {{ info.name }}
          </div>
          <div class="club-log frcc">
            <img :src="info.head_pic" alt="" />
          </div>
          <div class="club-intro">{{ info.short }}</div>
        </div>
        <div class="subscription frcb" @click="handlerShow">
          <div>
            活动年费标准
          </div>
          <img class="right-arrow" src="@/assets/images/rught_arrow.png" alt="" />
        </div>
        <div class="about-content">
          <div class="frcb">
            <div class="about-title">
              关于我们
            </div>
            <div class="expand frcb" @click="handlerExpand">
              {{ show2 ? "展开" : "收起" }}
              <img class="top-arrow" :class="show2 ? 'show' : ''" src="@/assets/images/top_arrow.png" alt="" />
            </div>
          </div>
          <div v-if="!show2" class="content-info" v-html="info.introduction"></div>
        </div>
        <div class="activity">
          <div class="activity-title-bar frcb">
            <div class="notice-title">活动通知</div>
            <div class="all frc" @click="
              $router.push({
                name: 'ClubNotice',
                params: {
                  id: id
                }
              })
            ">
              <div>全部</div>
              <img class="right-arrow" src="@/assets/images/rught_arrow.png" alt="" />
            </div>
          </div>
          <div class="activity-list">
            <div class="activity-item frcb" v-for="(item, index) in noticeList" :key="index" @click="
              $router.push({
                name: 'ClubNoticeDetails',
                params: {
                  id: item.id,
                  pid: id
                }
              })
            ">
              <div class="activity-item-left-box fcb">
                <div class="activity-item-title">
                  {{ item.title }}
                </div>
                <div class="frcb">
                  <div class="activity-item-views">{{ item.views }}阅读</div>
                  <div class="activity-item-time">
                    {{ item.create_time }}
                  </div>
                </div>
              </div>
              <div class="activity-item-right-box">
                <img :src="item.head_pic" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="activity">
          <div class="activity-title-bar frcb">
            <div class="notice-title">全部动态</div>
            <div class="all frc" @click="
              $router.push({
                name: 'ClubDynamic',
                params: {
                  id: id
                }
              })
            ">
              <div>全部</div>
              <img class="right-arrow" src="@/assets/images/rught_arrow.png" alt="" />
            </div>
          </div>
          <div class="activity-list">
            <div class="activity-item frcb" v-for="(item, index) in dynamicList" :key="index" @click="
              $router.push({
                name: 'ClubDynamicDetails',
                params: {
                  id: item.id,
                  pid: id
                }
              })
            ">
              <div class="activity-item-left-box fcb">
                <div class="activity-item-title">
                  {{ item.title }}
                </div>
                <div class="frcb">
                  <div class="activity-item-views">{{ item.views }}阅读</div>
                  <div class="activity-item-time">
                    {{ item.create_time }}
                  </div>
                </div>
              </div>
              <div class="activity-item-right-box">
                <img :src="item.head_pic" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="components-content" v-else>
        <div class="placed-the-top-project" @click="
          $router.push({
            name: 'SchoolBrandProjectDetails',
            params: {
              id: topData.id,
              pid: 29
            }
          })
        ">
          <div class="placed-the-top-project-cover">
            <img :src="topData.head_pic" alt="" />
          </div>
          <div class="placed-the-top-project-title">
            {{ topData.title }}
          </div>
          <div class="placed-the-top-project-intro" v-if="topData.content"
            v-html="topData.content.replace(/<[^>]+>/g, '')"></div>
          <div class="placed-the-top-project-time-bar frcb">
            <div class="placed-the-top-project-time">
              {{ topData.create_time }}
            </div>
            <div class="placed-the-top-project-more frc">
              <div class="more">更多</div>
              <img class="more-iocn" src="@/assets/images/news_right_arrow.png" alt="" />
            </div>
          </div>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="brand-project-list">
            <div class="brand-project-item" v-for="(item, index) in list" :key="index" @click="
              $router.push({
                name: 'SchoolBrandProjectDetails',
                params: {
                  id: item.id,
                  pid: 29
                }
              })
            ">
              <div class="brand-project-item-cover">
                <img :src="item.head_pic" alt="" />
              </div>
              <div class="brand-project-item-title">
                {{ item.title }}
              </div>
              <div class="brand-project-item-time">
                {{ item.create_time }}
              </div>
              <div class="brand-project-item-intro" v-html="item.content ? item.content.replace(/<[^>]+>/g, '') : ''">
              </div>
              <div class="placed-the-top-project-more frc">
                <div class="more">更多</div>
                <img class="more-iocn" src="@/assets/images/news_right_arrow.png" alt="" />
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </template>
    <van-popup v-model="show1" round :closeable="true" position="bottom" :style="{ 'min-height': '30%' }">
      <div class="cost-title frcc">
        活动年费标准
      </div>
      <div class="const-info">
        {{ info.price }}
      </div>
    </van-popup>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import SwiperBox from "@/components/SwiperBox";
import BottomBar from "@/components/BottomBar";
import { fetchClubInfo, fetchClubArticle } from "@/api/club.js";
export default {
  name: "Club",
  components: {
    TopHead,
    SwiperBox,
    BottomBar
  },
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      info: {},
      swipers: [],
      noticeList: [],
      dynamicList: [],
      list: [],
      loading: false,
      finished: false,
      topData: {}
    };
  },
  watch: {
    $route() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.id === -1) {
        const { result } = await fetchClubArticle({
          cate_id: 32,
          pageSize: 999999,
          pageNo: 1
        });
        console.log(result)
        this.list.push(...result.data);
        this.topData = this.list[0];
        this.loading = false;
        if (this.list.length >= result.totalCount) {
          this.finished = true;
        }
      }
    },
    handlerExpand() {
      this.show2 = !this.show2;
    },
    handlerShow() {
      console.log(111);
      this.show1 = true;
    },
    async getData() {
      console.log(this.id, "id..");
      if (this.id == -1) {
        return;
      }
      const { result } = await fetchClubInfo(this.id);
      const n = await fetchClubArticle({
        cate_id: 31,
        club_id: this.id,
        pageSize: 3,
        pageNo: 1
      });
      const d = await fetchClubArticle({
        cate_id: 32,
        club_id: this.id,
        pageSize: 3,
        pageNo: 1
      });
      this.noticeList = n.result.data;
      this.dynamicList = d.result.data;
      this.info = result;
      this.swipers = result.rotation.split("|");
    },
    showMenu() {
      this.show = !this.show;
    },
    closeMenu() {
      this.show = false;
    },
    chooseMenu(item) {
      this.$router.push({
        name: "Club",
        params: {
          id: item.id
        }
      });
      this.show = false;
    }
  },
  computed: {
    active() {
      const id = this.$route.params.id;
      const item = this.clubList.find(item => item.id == id);
      console.log(this.clubList);
      if (item) {
        return item;
      } else {
        return {};
      }
    },
    clubList() {
      return this.$store.getters.clubList;
    },
    id() {
      return this.$route.params.id;
    }
  }
};
</script>

<style lang="less" scoped>
.club-box {
  padding-top: 13vw;
  position: relative;
  background: #f5f6f8;

  .club-title-bar {
    padding: 4.96vw 3vw 0 3vw;
    box-sizing: border-box;
    position: relative;
    top: -2vw;
    height: 22vw;
    width: 100vw;
    background: #fff;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;

    .club-title {
      font-family: PingFang-SC-Bold;
      font-size: 4vw;
      color: #404040;
    }

    .club-log {
      position: absolute;
      right: 3vw;
      top: -5vw;
      width: 16vw;
      height: 16vw;
      background: #fff;
      border-radius: 50%;

      img {
        width: 15vw;
        height: 15vw;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .club-intro {
      position: absolute;
      left: 3vw;
      top: 12vw;
      font-family: PingFang-SC-Regular;
      font-size: 3vw;
      color: #838385;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //作为弹性伸缩盒子模型显示。
      -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
      -webkit-line-clamp: 2; //显示的行
    }
  }
}

.menu-wrapper {
  position: fixed;
  width: 100vw;
  z-index: 1;
  top: 15vw;
  box-sizing: border-box;

  .active-menu {
    padding: 0 3vw;
    height: 13vw;
    font-size: 4vw;
    color: #404040;
    background-color: #f4f4f4;

    .arrow-icon {
      transition: all 0.3s ease-in-out;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }

  .menu-list {
    width: 100%;
    background: #ffffff;
    // position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.active {
      max-height: 88vw;
    }

    .menu-item {
      margin: 5vw 0 10vw 0;
      padding: 0 3vw;
      font-family: PingFang-SC-Medium;
      font-size: 4vw;
      color: #404040;

      &.active-color {
        color: #007069;
      }
    }
  }
}

.subscription {
  padding: 0 3vw;
  height: 13vw;
  background: #fff;
  font-family: PingFang-SC-Medium;
  font-size: 4vw;
  color: #404040;

  .right-arrow {
    height: 3vw;
  }
}

.cost-title {
  margin-top: 8vw;
}

.const-info {
  margin-top: 5vw;
  padding: 0 3vw;
  font-family: PingFang-SC-Medium;
  font-size: 3vw;
  color: #333333;
}

.about-content {
  margin-top: 2vw;
  padding: 0 3vw;
  padding-bottom: 3vw;
  background: #fff;
}

.about-title {
  padding-top: 2.96vw;
  font-family: PingFang-SC-Bold;
  font-size: 4vw;
  color: #404040;
}

.expand {
  padding-top: 2.96vw;
  font-family: PingFang-SC-Bold;
  font-size: 3vw;
  color: #838385;
}

.top-arrow {
  margin-left: 1vw;
  width: 3vw;
  transition: all 0.3s ease-in-out;

  &.show {
    transform: rotate(180deg);
  }
}

.activity {
  background: #fff;
  margin-top: 2vw;
  padding: 0 3vw;

  .activity-title-bar {
    padding-top: 2vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #404040;
  }

  .all {
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #a2a2a3;
    white-space: nowrap;

    .right-arrow {
      height: 3vw;
      margin-left: 2vw;
    }
  }
}

.activity {
  padding-bottom: 4vw;
}

.activity-list {
  .activity-item {
    height: 29vw;
    border-bottom: 1px solid #e6e6e6;

    .activity-item-left-box {
      width: 57vw;
      height: 21vw;

      .activity-item-title {
        font-family: PingFang-SC-Medium;
        font-size: 4vw;
        color: #404040;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .activity-item-views {
        font-family: PingFang-SC-Regular;
        font-size: 3vw;
        color: #a2a2a3;
      }

      .activity-item-time {
        font-family: PingFang-SC-Regular;
        font-size: 3vw;
        color: #a2a2a3;
      }
    }

    .activity-item-right-box {
      width: 32vw;
      height: 21vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<style lang="less">
.content-info {
  background: #fff;

  img {
    max-width: 100%;
  }
}
</style>

<style lang="less" scoped>
.placed-the-top-project {
  background: #f2fbfa;
  padding-bottom: 7vw;

  .placed-the-top-project-cover {
    width: 100%;
    height: 62vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .placed-the-top-project-title {
    padding: 5vw 3vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #404040;
  }

  .placed-the-top-project-intro {
    padding: 0 3vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #838385;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .placed-the-top-project-time-bar {
    padding: 0 3vw;
    margin-top: 7vw;

    .placed-the-top-project-time {
      font-family: PingFang-SC-Regular;
      font-size: 3vw;
      color: #838385;
    }
  }
}

.brand-project-list {
  margin-top: 11vw;
}

.brand-project-item {
  padding-bottom: 6vw;
  border-bottom: 1vw solid #f0faf9;
  margin-bottom: 7vw;

  .brand-project-item-cover {
    width: 100%;
    height: 62vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .brand-project-item-title {
    margin-top: 5vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #404040;
  }

  .brand-project-item-time {
    margin: 4vw 0;
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #a2a2a3;
  }

  .brand-project-item-intro {
    margin-bottom: 7vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    line-break: strict;
    color: #404040;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .more-box {
    margin-top: 7vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #017069;

    .more-icon {
      margin-left: 2vw;
      width: 2vw;
    }
  }
}

.placed-the-top-project-more {
  .more {
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #007069;
  }

  .more-iocn {
    margin-left: 2vw;
    width: 2vw;
  }
}
</style>
